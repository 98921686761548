* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-family: "cabin";
}

.navbar .navbar-icon,
.navbar .navbar-text {
  color: #000000;
}

a {
  text-decoration: none;
  color: rgb(255, 255, 255);
  font-style: italic;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #ffffff;
  max-width: 1100px;
  margin: 0 auto;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

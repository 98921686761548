.navbar {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 60px;
    background-color: transparent;
    z-index: 1000;
}

.navbar-logo {
    display: flex;
    align-items: center;
    margin-right: 20px;
    cursor: pointer;
}

.navbar-logo:hover .navbar-text {
    opacity: 1;
}

.navbar-icon {
    font-size: 24px;
    margin-right: 8px;
    color: #000000 !important; /* Force icon color to black */
}

.navbar .navbar-text {
    color: #000000 !important; /* Force text color to black */
    font-size: 14px;
    opacity: 0;
    transition: opacity 0.3s ease;
}

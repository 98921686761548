.TeamPage {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    grid-column-gap: 10px;
    grid-row-gap: 10px;

    .team-name-section {
        grid-column: span 3;
        justify-self: start;
        align-self: end;
    }

    .match-detail-section {
        grid-column: span 4;
    }

    .team-name {
        font-size: 4rem;
        margin-bottom: 20px;
    }

    .win-loss-section {
        justify-self: center;
        padding: 40px;
        text-align: center;
    }

    .more-link {
        align-self: center;
        justify-self: center;
        font-size: 2rem;
        color: rgb(255, 255, 255);
    }

    .outside-fonts {
        color: black;
    }
}
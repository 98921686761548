.App {
  text-align: left;
  padding: 20px;
}

.won-card {
  background-color: #27b21f;
}

.lost-card {
  background-color: #fe3c3c;
}
.HomePage {
    .team-grid {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 10px;
        grid-row-gap: 10px;
    }

    .app-name {
        font-size: 4rem;
        margin: 30px auto;
        text-align: center;
        color: black;
    }

    .app-info {
        font-size: 3rem;
        text-align: center;
        color: black;
    }

    hr {
        overflow: visible;
        /* For IE */
        height: 30px;
        border-style: solid;
        border-color: black;
        border-width: 2px 0 0 0;
        border-radius: 20px;
    }

    hr:before {
        /* Not really supposed to work, but does */
        display: block;
        content: "";
        height: 30px;
        margin-top: -31px;
        border-style: solid;
        border-color: black;
        border-width: 0 0 2px 0;
        border-radius: 20px;
    }
}
.MatchDetailCard {
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: 10px;
    margin-bottom: 20px;
    color: #ffffff;
    .vs {}

    .match-date .match-venue .match-result {
        margin-top: 10px;
    }

    .additional-details {
        text-align: right;

        &>p {
            margin-bottom: 20px;
        }
    }
}
.TeamTile {
    height: 170px;
    text-align: center;
    border: 1px solid rgb(96, 96, 96);
    padding: 25px;
    color: rgb(255, 255, 255);
    background-color: #ececec;

    a {
        color: #000000;
    }
}